#header {

}

.shake:hover {
    animation: shake 0.7s cubic-bezier(0.36, 0.07, 0.19, 0.97);
}

@keyframes shake {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(8deg); }
    20% { transform: rotate(-6deg); }
    30% { transform: rotate(4deg); }
    40% { transform: rotate(-3deg); }
    50% { transform: rotate(2deg); }
    60% { transform: rotate(-1deg); }
    70% { transform: rotate(1deg); }
    80% { transform: rotate(-1deg); }
    90% { transform: rotate(0.5deg); }
    100% { transform: rotate(0deg); }
}
