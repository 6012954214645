@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Bricolage Grotesque', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #102156;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion) {
  * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
    animation-name: none !important;
  }

  #me {
    opacity: 1 !important;
  }

  .transparent {
    opacity: 1 !important;
  }

  .skill {
    opacity: 1 !important;
  }

  .projectCard {
    opacity: 1 !important;
  }

  .footer-icon {
    filter: none !important;
    border-radius: 1rem !important;
}

  .shapes {
    opacity: 0 !important;
  }

}