#projects {
    background-image: url('../../SVGs/shell.svg');
}

.projectCard {
    overflow: hidden;
    border-color: #102156;
}

.projectCard img {
    transition: filter .2s ease-in-out;
    filter: grayscale(100%);
}

.projectCard:hover img {
    filter: grayscale(0%)
}

.cardTitle {
    transition: color .2s ease-in-out;
}

.cardTitle:hover {
    color: rgb(116, 36, 255);
}

.modal-section {
    filter: grayscale(0%) !important;
}

.project-title {
        text-shadow: -1px 2px 0 #8c57ff,
    4px 2px 0 #8c57ff,
    4px -1px 0 #8c57ff,
    -1px -1px 0 #8c57ff;
}

.scale-in {
    animation: scale-in 1s ease-in-out forwards;
    opacity: 0;
}

@keyframes scale-in {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    40% {
        transform: scale(1.15);
    }
    70% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}