#skills {
    min-height: 80vh;
    color: white;
    background: url('../../SVGs/Wave-10s-1920px.svg');
    background-size: auto;
    background-repeat: repeat;
}

.container {
    background-image: linear-gradient(43deg, #4158D0 0%, #9e50c8 46%, #adddfa 100%);
    filter: brightness(100%);
    z-index: 1;
}

.container:before {
    z-index:-2;
    position: fixed;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background-image: linear-gradient(43deg, #4158D0 0%, #8ab2fd 46%, #adddfa 100%); 
    filter: blur(40px);
}

.head {
    text-shadow: -1px 2px 0 #6488cd,
    4px 2px 0 #6488cd,
    4px -1px 0 #6488cd,
    -1px -1px 0 #6488cd;
}

.gradient {
    background-color: #0b090a;
    padding: 30px;
    color: white; 
    font-size: 35px;
    font-family: arial;
    text-align: center;
}

.skill-back {
    background-image: linear-gradient(43deg, #8395f2 0%, #7283e2 46%, #adddfa 100%); 
    filter: brightness(100%);
    z-index: 1;
}

.transparent {
    opacity: 0;
}

.pop-in {
    animation: pop-in 1s ease-in-out forwards;
    opacity: 0;
}

@keyframes pop-in {
    0% {
        transform: scale(0.5) translateY(-100px) rotate(-10deg);
        opacity: 0;
    }
    40% {
        transform: scale(1.15) translateY(20px) rotate(15deg);
    }
    70% {
        transform: scale(0.9) translateY(-10px) rotate(-5deg);
    }
    100% {
        transform: scale(1) translateY(0) rotate(0);
        opacity: 1;
    }
}