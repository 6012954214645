.name {
    font-family: 'Raleway';
}

#me {
    max-height: 30rem;
}

#about-me {
    min-height:100vh;
    position: relative;
    overflow: hidden;
    margin-top: -5vh;
      text-shadow: -1px 2px 0 #102156,
      4px 2px 0 #102156,
      4px -1px 0 #102156,
      -1px -1px 0 #102156;
}

.shapes {
  position: absolute;
  display: flex;
}

.svg {
    animation: animate 60s ease-in-out infinite;
    z-index: -100;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(50vh) translateX(0) translateZ(0) scale(1.2) rotate(0deg);
  }
  10% {
    opacity: 1;
    transform: translateY(0) translateX(-50vw) translateZ(20px) scale(1) rotate(45deg);
  }
  20% {
    opacity: 0.7;
    transform: translateY(-10vh) translateX(-30vw) translateZ(10px) scale(2.2) rotate(360deg);
  }
  30% {
    opacity: 0.9;
    transform: translateY(0) translateX(0vw) translateZ(0px) scale(1) rotate(135deg);
  }
  40% {
    opacity: 0.5;
    transform: translateY(-15vh) translateX(-15vw) translateZ(-14px) scale(1.4) rotate(180deg);
  }
  50% {
    opacity: 0.8;
    transform: translateY(0) translateX(-30vw) translateZ(-15px) scale(1) rotate(100deg);
  }
  60% {
    opacity: 0.6;
    transform: translateY(-20vh) translateX(-12vw) translateZ(-10px) scale(1.6) rotate(270deg);
  }
  70% {
    opacity: 0.7;
    transform: translateY(0) translateX(-10vw) translateZ(10px) scale(1) rotate(315deg);
  }
  80% {
    opacity: 0.4;
    transform: translateY(-25vh) translateX(-11vw) translateZ(20px) scale(1) rotate(360deg);
  }
  90% {
    opacity: 0.5;
    transform: translateY(0) translateX(-30vw) translateZ(10px) scale(1) rotate(405deg);
  }
  100% {
    opacity: 0;
    transform: translateY(-30vh) translateX(0vw) translateZ(-10px) scale(2) rotate(450deg);
  }
}

.slide-up {
    animation: slide-up 2s ease-in-out;
}

#me {
    min-width: 20rem;
    min-height: 20rem;
    opacity: 0;
    padding: 5px;
    background: linear-gradient(90deg, rgba(178,174,255,1) 0%, rgba(67,241,255,1) 13%, rgba(200,230,255,1) 43%, rgba(64,180,241,1) 75%, rgba(0,212,255,1) 100%);
    box-shadow: 0px 0px 20px 0px rgb(208, 255, 255);
    animation: delayedFadeIn 3s ease-in-out .2s forwards;
}

.transparent {
  opacity: 0;
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  55% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes slide-up {
    from {
        opacity: 0;
        transform:translateY(350px)
    }

    to {
        opacity: 1;
        transform:translateY(0)
    }
}

@media (width < 600px) {
  .svg {
    display: none;
  }
}